<template>
  <div class="studentinfo">
    <!-- <el-button href="#answers" @click="$router.push('answers')">查看答题情况</el-button> -->
    <el-container style="height: 100%; border: 1px solid #eee">
      <el-container>
        <el-main>
          <div class="bigDiv">
            <el-form label-width="80px" :inline="true">
              <el-form-item label="考试批次:">
                <el-select v-model="sBatch" placeholder="考试批次">
                  <el-option v-for="item in sBatchs" :key="item" :label="item" :value="item">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="学号:">
                <el-input v-model="username" placeholder="请输入学号" clearable></el-input>
              </el-form-item>
              <el-form-item label="姓名:">
                <el-input v-model="realname" placeholder="请输入姓名" clearable></el-input>
              </el-form-item>
              <el-button type="primary" @click="goSearch">查询</el-button>
              <!-- <el-button type="primary" @click="emptyAll">清空</el-button> -->
              <a href="/template/名单导入模板.xlsx" download="名单导入模板" style="margin-left: 10px;">
                <el-button type="primary" icon="el-icon-download">模板下载</el-button>
              </a>
              <el-form-item label="" style="margin-left: 10px">
                <el-upload class="upload-demo" :before-upload="NotBut" :on-success="fileSuccess"
                  :on-error="fileError" :action="StuFileUrl" :on-change="handleChange"
                  :file-list="fileList" :disabled="fileLoadStatus" name="multipartFile">
                  <el-button type="success" icon="el-icon-plus" :disabled="showBut">导入学生名单
                  </el-button>
                </el-upload>
              </el-form-item>

            </el-form>
            <el-table :data="lists" style="width: 100%" :stripe="true" :border="true"
              :header-cell-style="{background:'#eef1f6',color:'#606266'}">
              <el-table-column prop="s_batch" label="批次" width="120"></el-table-column>
              <el-table-column prop="s_username" label="学号" width="130"></el-table-column>
              <el-table-column prop="s_realname" label="姓名" width="80"></el-table-column>
              <el-table-column prop="s_password" label="密码" width="120"></el-table-column>
              <el-table-column prop="s_platform" label="平台"></el-table-column>
              <el-table-column label="操作" width="120">
                <template slot-scope="scope">
                  <el-button size="mini" type="primary"
                    @click="handleShow(scope.$index, scope.row)">修改信息</el-button>
                  <!-- <el-button size="mini" type="danger"
                    @click="handleDelete(scope.$index, scope.row)">
                    删除</el-button> -->
                </template>
              </el-table-column>
              <div slot="empty">
                <p>
                  <img :src="imgUrl" />
                </p>
              </div>
            </el-table>
            <el-pagination :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
              @current-change="handleCurrentChange" :page-sizes="[100, 200, 300, 400]"
              @size-change="handleSizeChange" :total="allLength || 0" align="right">
            </el-pagination>
          </div>
        </el-main>
        <!-- 修改学生信息 -->
        <el-dialog :visible.sync="showmessage" showCancelButton=true showConfirmButton=true
          title="修改学生信息">
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px"
            class="demo-ruleForm">
            <el-form-item label="学号：" prop="s_username">
              <el-input v-model="ruleForm.s_username"></el-input>
            </el-form-item>
            <el-form-item label="姓名：" prop="s_realname">
              <el-input v-model="ruleForm.s_realname"></el-input>
            </el-form-item>
            <el-form-item label="密码：" prop="s_password">
              <el-input v-model="ruleForm.s_password"></el-input>
            </el-form-item>
            <el-form-item label="平台：">
              <el-input v-model="ruleForm.s_platform"></el-input>
            </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button @click="updateNo">取消</el-button>
            <el-button type="primary" @click="updateinfo('ruleForm')">修 改</el-button>
          </span>
        </el-dialog>
      </el-container>

    </el-container>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import { parseTime } from '@/common/date.js' // 在组件中引用date.js,时间戳
import commonApi from '@/api/commonApi.js'
import StuFileUrl from '@/common/fileUrl.js'

export default {
  data () {
    // name: 'Home'
    return {
      // 上传按钮状态
      fileLoadStatus: false,
      showBut: false,
      tableHeight: window.innerHeight - 300,
      ruleForm: {},
      rules: {
        s_realname: [
          { required: true, message: '错了哦，学号不能为空', trigger: 'blur' }
        ],
        s_username: [
          { required: true, message: '错了哦，姓名不能为空', trigger: 'blur' }
        ],
        s_password: [
          { required: true, message: '错了哦，密码不能为空', trigger: 'blur' }
        ]
      },
      StuFileUrl: StuFileUrl.StuFileUrl, // 导入学生信息的上传Url
      realname: '', // 姓名
      username: '', // 学号
      cname: [], // 科目名称
      cid: '', // 选中的科目名称
      tname: [], // 根据科目名称查询任务
      sc_task: '', // 选中的任务名称
      fileList: [], // 存放文件的数组
      showmessage: false, // 查看弹窗
      labelPosition: 'right',
      formLabelAlign: {
        sc_realname: '',
        sc_username: '',
        sc_password: '',
        sc_status: ''
      },
      dstatus: [{
        name: '单选题',
        answerStatus: 0
      }, {
        name: '多选题',
        answerStatus: 1
      }, {
        name: '填空题',
        answerStatus: 2
      }, {
        name: '附件题',
        answerStatus: -1
      }], // 答题状态
      answerStatus: '', // 选中的答题状态
      lists: [
        {
          sc_realname: '吴彦祖', sc_username: '123456', sc_password: '123456', sc_status: '正常'
        },
        {
          sc_realname: '彭于晏', sc_username: '521035', sc_password: '123456', sc_status: '正常'
        },
        {
          sc_realname: '胡歌', sc_username: '821532', sc_password: '123456', sc_status: '正常'
        }
      ], // 获取到的数据
      indexPage: 1, // 当前页数，默认第一页
      pageSize: 100, // 每页显示条数
      allLength: 0, // 查询到的数据总条数
      allPageNumber: 0, // 查询到的数据总页数
      imgUrl: require('../assets/Lark20201216-174717.png'), // 图片地址
      // 当前选中的批次
      sBatch: '',
      // 所有批次列表
      sBatchs: []
    }
  },
  mounted () {
    // this.allCourseName()
    this.getAllBatchs()
  },
  methods: {
    // 获取所有批次
    async getAllBatchs () {
      const res = await commonApi.getAllBatchs()
      this.sBatchs = (res || []).reverse()
      this.sBatch = res.length > 0 ? res[0] : ''
      this.goSearch()
    },
    // 查看信息
    handleShow: function (index, rows) {
      this.showmessage = true
      this.ruleForm = rows
    },
    // 删除题库
    handleDelete: function (index, rows) {
    },
    // 弹框下的修改学生信息按钮
    updateinfo: function (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const data = {}
          if (this.ruleForm.s_id !== null) data.sid = this.ruleForm.s_id
          if (this.ruleForm.s_password) data.spassword = this.ruleForm.s_password
          if (this.ruleForm.s_platform) data.splatform = this.ruleForm.s_platform
          if (this.ruleForm.s_realname) data.srealname = this.ruleForm.s_realname
          if (this.ruleForm.s_username) data.susername = this.ruleForm.s_username
          // 调取接口
          commonApi.updateStudentInfo(data).then((response) => {
            this.$message({
              message: '修改信息成功',
              type: 'success'
            })
          }).catch((response) => {
            console.log(response)
          })
          this.showmessage = false
        } else {
          return false
        }
      })
    },
    // 上传时
    NotBut: function (file) {
      this.fileLoadStatus = true
      this.showBut = true
    },
    // 文件上传成功的提示
    fileSuccess: function (response, file, fileList) {
      if (response.code === -8) {
        this.$message({
          message: '重复提交',
          type: 'error'
        })
      } else if (response.code === -3) {
        this.$message({
          message: '该科目没有形考任务',
          type: 'error'
        })
      } else {
        this.$message({
          message: '导入学生信息成功',
          type: 'success'
        })
        this.goSearch()
      }
      this.this.fileLoadStatus = false
      this.showBut = false
    },
    // 文件上传失败的提示
    fileError: function () {
      this.$message({
        message: '导入学生信息失败',
        type: 'error'
      })
      this.this.fileLoadStatus = false
      this.showBut = false
      this.goSearch()
    },
    // 导入题库exl表
    handleChange (file, fileList) {
      this.fileList = fileList.slice(-3)
    },
    // 侧边栏展开
    handleOpen: function (key, keyPath) {
    },
    // 侧边栏关闭
    handleClose: function (key, keyPath) {
    },
    updateNo: function () {
      this.showmessage = false
      this.goSearch()
    },
    // 清空查询全部
    emptyAll: function () {
      this.realname = ''
      this.username = ''
      const data = {
        current: this.indexPage, // 当前页
        size: this.pageSize // 每页数据条数
      }
      commonApi.listStudent(data).then((response) => {
        this.lists = response.records || []
        this.allLength = Number(response.total)
        this.allPageNumber = this.allLength / this.pageSize || 1
      }).catch((response) => {
        console.log(response)
      })
    },
    // 查询数据
    goSearch: function () {
      const data = {
        batch: this.sBatch,
        current: this.indexPage, // 当前页
        size: this.pageSize // 每页数据条数
      }
      if (this.realname) data.realName = this.realname
      if (this.username) data.userName = this.username
      commonApi.listStudent(data).then((response) => {
        this.lists = response.records || []
        this.allLength = Number(response.total)
        this.allPageNumber = this.allLength / this.pageSize || 1
      }).catch((response) => {
        console.log(response)
      })
    },
    // 页数切换
    handleCurrentChange (cpage) {
      this.indexPage = cpage
      this.goSearch()
    },
    // 每页条数切换
    handleSizeChange (val) {
      // 获取每页数据条数
      this.pageSize = val
      // 触发查询操作
      this.goSearch()
    },
    // 时间戳转化声明
    parseTime: parseTime
  },
  watch: {

    // 监听答题状态改变时触发查询操作
    answerStatus (val, oldVal) {
      this.indexPage = 1
      this.allPageNumber = 0
      // 触发查询操作
      this.goSearch()
    },

    // 监听任务名称改变时触发查询操作
    sc_task (val, oldVal) {
      this.indexPage = 1
      this.allPageNumber = 0
      // 触发查询操作
      this.goSearch()
    }
  }
}
</script>

<style>
.el-header {
  background-color: #b3c0d1;
  color: #333;
  line-height: 60px;
}
.el-aside {
  color: #333;
}
.disrow {
  display: flex;
  flex-direction: row;
}
</style>
